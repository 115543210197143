import { Stack } from "@mui/material";

export const MainScreen = () => {
  return (
    <Stack
      height="100%"
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <div>
        <div
          style={{
            width: "40vw",
            height: "40vh",
            backgroundImage: 'url("/img/logo.png")',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        />
      </div>
    </Stack>
  );
};
