/* eslint-disable import/no-anonymous-default-export */
import { MainScreen } from "./screens/main-screen";
import { Route, Routes, BrowserRouter } from "react-router-dom";

export default () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainScreen />} />
      </Routes>
    </BrowserRouter>
  );
};
